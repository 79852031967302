const state = {
	devSn: "", // 设备sn码
	mid: "", // 材质编码
	custId: 0, // 客户id
	type: 1, // 客户归属地：1 中国，2 外国
	firstLoadPdf: true, // 标记第一次加载 Pdf
	picId: '', // 图片id
	pdfImg: '', // 切图
	spriteArr: [], // Canvas 图层信息
	modelId: '', // 型号id
	modelName: '', // 型号
}

export default state
