export const SET_DEVSN = 'SET_DEVSN'

export const SET_MID = 'SET_MID'

export const SET_CUSTID = 'SET_CUSTID'

export const SET_TYPE = 'SET_TYPE'

export const SET_FIRSTLOADPDF = 'SET_FIRSTLOADPDF'

export const SET_PICID = 'SET_PICID'

export const SET_PDFIMG = 'SET_PDFIMG'

export const SET_SPRITEARR = 'SET_SPRITEARR'

export const SET_MODELID = 'SET_MODELID'

export const SET_MODELNAME = 'SET_MODELNAME'