module.exports = {
	C: {
		Next: 'Successivo',
		Copyright: 'Ufficiale',
		Tips: "Suggerimento: Il tratteggio rosso indica l'area dove verrà effettuata la stampa;",
		Tips2: "L'immagine reticolata sul cellulare è un'anteprima del prodotto finito;",
		Print: 'Risultato del risultato della stampa',
		PResult1: 'Normale',
		PResult2: 'Sfuocatura',
		PResult3: 'Chiara',
		NoData: 'Nessun dato',
		ChooseModel: 'Scegli il modello del telefono',
		HadChooseModel: 'Cambia modello',
		Cancel: 'Cancella',
		Confirm: 'Conferma',
		Restore: 'Tornare alle impostazioni iniziali',
		Return: 'Torna indietro',
		Warning: 'Attenzione',
		GongNeng: 'Personalizza',
		TuKu: 'Galleria',
		Photo: 'Foto',
		Sticker: 'Adesivo',
		Text: 'Prossimo',
		AddText: 'Aggiungi il testo',
		PleaseChoose: 'Seleziona il carattere',
		PleaseChooseText: 'Per favore inserisci il contenuto del testo',
		PictureEdit: "Aggiusta l'immagine",
		WarningTips: "Per favore carica l'immagine in formato jpg o jpeg",
		UploadLimit: "L'immagine caricata non può superare i 30MB!",
		ModelTips: 'Per prima cosa scegliere il modello!',
		PicTips: "Poi caricate l'immagine!",
		PicChooseTips: "Aggiustatete l'immaggine",
		PicMake: 'Produzione',
		PicLoad: "Carica l'immagine",
		Loading: 'Caricamento',
		Reminder: 'Ricorda',
		UploadTips0: 'Si prega di leggere e controllare',
		UploadTips1: 'Assicurati che le immagini caricate siano chiare e utilizzabili, e non caricare immagini illegali o illegali. Si prega di leggere attentamente',
		UploadTips2_1: 'Accordo di personalizzazione del modello adesivo del telefono cellulare',
		UploadTips2_2: 'Protocollo di personalizzazione del modello della custodia del telefono',
		UploadTips3: "Facendo clic su 'Accetto' si indica di aver letto e accettato l'accordo.",
		Back: 'Non accettare',
		Know: 'Accettare e continuare',
		PhoneProtocol: 'Personalizzazione del protocollo del telefono',
		NoPic: 'Non ci sono altre immagini',
		NoSn: "Non esistono informazioni sull'apparecchio",
		NoMid: 'Non esistono informazioni sul materiale',
		CusDisabled: 'La personalizzazione è stata disabilitata',
		NoCustomerInfo: 'Non esistono le informazioni del venditore',
		SnDisabled: 'Il dispositivo non funziona in modo corretto. Per cortesia contatta il servizio clienti.',
		NoSystem: 'Non ancora configurato',
		Loadingg: 'Caricamento',
		GeneratingOrders: 'Generagli ordini',
		DownloadInfo: "Per favore fai uno screenshot per salvare l'immagione o usa il browser per aprire e salvare l'immagine",
		DownloadLoading: "Salvataggio dell'immagine",
		DownloadSuccess: "L'immagine è stata salvata con successo nell'album",
		PhotoChange: "Per cortesia riseleziona l'immagine perché è stato cambiato il modello.",
		SelectPicture: "Seleziona l'immagine",
		MapTransfer: "Carica l'immagine",
		ClosePicture: 'Chiudi',
		LoadingResource: 'Le risorse si stanno caricando',
		NoModelList: "I dati del modello non sono stati trovati, ricaricare.",
		NoModelLists: "Nessun dato",
		BrandName: "Marchio dell'apparecchio",
		JixinName: "Nome del modello.",
		SearchContent: 'Cerca il modello del cellulare.',
		PopularModels: 'Marchio del modello',
		SuccessRestore: 'Recupero riuscito',
		SuccessRestoreRotate: 'Angolo di rotazione e inizializzazione riusciti',
		FunctionNoOpen: 'Questa funzione è ancora in via di sviluppo',
		Rotate: 'Ruota',
		Scale: 'Gradazione',
		Acoustic: 'Acustica',
		RotateLeft15: '15 gradi',
		RotateRight15: '15 gradi',
		ReadAndCheckTheAgreement: 'Per favore leggi e controlla prima la dichiarazione',
		FlipHorizontal: 'Inversione orizzontale',
		FlipVertical: 'Capovolgi verticale',
		FlipHorizontalYes: 'Attivazione orizzontale',
		FlipHorizontalNo: 'Spostamento orizzontale disattivato',
		FlipVerticalYes: 'Attivato capovolgimento verticale',
		FlipVerticalNo: 'Spostamento verticale disattivato'
	},
	P: {
		ConfirmPrint: 'Conferma la stampa',
		PrintPreview: 'Anteprima del rendering',
		Model: 'Modello del cellulare',
		Remark: "Osserva le informazioni, come il numero di telefono, l'indirizzo mail, per richieste d'ordine.",
		SubmitPrint: 'Invia alla stampa'
	},
	S: {
		SubmitSuccess: 'stampato con successo',
		PrintInfo: 'Informazioni sul vostro ordine',
		PrintSceen: 'Per favore fate uno screenshot e mostratelo al personale per stampare.',
		PrintNo: 'Stampa n.',
		Save: 'Salva la foto in..',
		CancelOrder: "Cancella l'ordine",
		CancelOrderSuccess: 'Ordine validato con successo',
		CancelOrderFail: "La cancellazione dell'ordine è fallita. Contattate l'assistenza.",
		ScreenshotSave: "Si prega di prendere uno screenshot del telefono per salvare le informazioni dell'ordine",
		copySuccess: 'Copia riuscita',
		copyError: 'copia non riuscita'
	}
}
