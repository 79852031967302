module.exports = {
	C: {
		Next: 'Next',
		Copyright: 'Official',
		Tips: 'Tips: the red dotted box is the area where the image will be printed;',
		Tips2: 'The phone wireframe picture is a preview of the finished product;',
		Print: 'Printing result',
		PResult1: 'Normal',
		PResult2: 'Blur',
		PResult3: 'Clear',
		NoData: 'No data',
		ChooseModel: 'Choose a phone model',
		HadChooseModel: 'Change model',
		Cancel: 'Cancel',
		Confirm: 'Confirm',
		Restore: 'Restore',
		Return: 'Return',
		Warning: 'Warning',
		GongNeng: 'Custom',
		TuKu: 'Gallery',
		Photo: 'Photo',
		Sticker: 'Sticker',
		Text: 'Text',
		AddText: 'Add text',
		PleaseChoose: 'Please select a font',
		PleaseChooseText: 'Please enter text content',
		PictureEdit: 'Adjust Picture',
		WarningTips: 'Please upload an image in png, jpg or jpeg format',
		UploadLimit: 'Upload image size cannot exceed 30MB!',
		ModelTips: 'Please choose the model first!',
		PicTips: 'Please upload the image first!',
		PicChooseTips: 'Please select the picture to be adjusted first',
		PicMake: 'Producing',
		PicLoad: 'Uploading',
		Loading: 'Loading',
		Reminder: 'Reminder',
		UploadTips0: 'Please read and check ',
		UploadTips1: 'Please ensure that the uploaded pictures are clear and available, and do not upload illegal pictures. Please read the ',
		UploadTips2_1: 'Agreement for Pattern Customization of Phone Skin',
		UploadTips2_2: 'Phone Case Pattern Customization Protocol',
		UploadTips3: 'carefully. Click "agree and continue" to indicate that you have read and agreed the Agreement.',
		Back: 'Disagree',
		Know: 'Agree and continue',
		PhoneProtocol: 'Pattern Customization Protocol',
		NoPic: 'No more images!',
		NoSn: 'Device information does not exist',
		NoMid: 'Material information does not exist',
		CusDisabled: 'Customer has been disabled',
		NoCustomerInfo: 'Customer information does not exist',
		SnDisabled: 'Device abnormality, please contact customer service!',
		NoSystem: 'No configuration yet',
		Loadingg: 'Loading',
		GeneratingOrders: 'Generating Orders',
		DownloadInfo: 'Please take a screenshot to save the picture or use the browser to open and save the picture',
		DownloadLoading: 'Saving pictures',
		DownloadSuccess: 'The picture has been successfully saved to the album',
		PhotoChange: 'Please reselect the picture due to the change of model',
		SelectPicture: 'Select picture',
		MapTransfer: 'Upload picture',
		ClosePicture: 'close',
		LoadingResource: 'Resources are loading',
		NoModelList: "No model data, please re-enter",
		NoModelLists: "No model data",
		BrandName: "Brand Name",
		JixinName: "Model Name",
		SearchContent: 'Search mobile phone model',
		PopularModels: 'Brand model',
		SuccessRestore: 'Recovery succeeded',
		SuccessRestoreRotate: 'Rotation angle initialization succeeded',
		FunctionNoOpen: 'This function is under development',
		Rotate: 'Revolve',
		Scale: 'Scale',
		Acoustic: 'Acoustic',
		RotateLeft15:'15 deg',
		RotateRight15: '15 deg',
		ReadAndCheckTheAgreement: 'Please read and check the agreement first',
		FlipHorizontal: 'Flip horizontal',
		FlipVertical: 'Flip vertical',
		FlipHorizontalYes: 'Horizontal flip enabled',
		FlipHorizontalNo: 'Horizontal flipping turned off',
		FlipVerticalYes: 'Vertical flip enabled',
		FlipVerticalNo: 'Vertical flipping turned off'
	},
	P: {
		ConfirmPrint: 'Confirm printing',
		PrintPreview: 'Rendering preview',
		Model: 'Phone Model',
		Remark: 'Remark information, such as: phone number, email address, for order inquiry',
		SubmitPrint: 'Submit to print'
	},
	S: {
		SubmitSuccess: 'Submit successfully~',
		PrintInfo: 'Your order information',
		PrintSceen: 'Please take a screenshot to save and show it to the staff for printing',
		PrintNo: 'Print No',
		Save: 'Save to [Photos]',
		CancelOrder: 'Cancel Order',
		CancelOrderSuccess: 'Order voided successfully',
		CancelOrderFail: 'Order cancellation failed. Please contact customer service',
		ScreenshotSave: 'Please use a screenshot of your phone to save the order information',
		copySuccess: 'copy success',
		copyError: 'copy failed'
	}
}
