export const devSn = state => state.devSn

export const mid = state => state.mid

export const custId = state => state.custId

export const type = state => state.type

export const firstLoadPdf = state => state.firstLoadPdf

export const picId = state => state.picId

export const pdfImg = state => state.pdfImg

export const spriteArr = state => state.spriteArr

export const modelId = state => state.modelId

export const modelName = state => state.modelName