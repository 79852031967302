import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueClipboard from 'vue-clipboard2'
import {
	Field,
	Icon,
	Tab,
	Tabs,
	Lazyload,
	Toast,
	Checkbox,
	Uploader
} from 'vant'

import VueI18n from 'vue-i18n' //  多语种
import langZhCn from '../static/lang/zh-cn.js' //  简体中文
import langZhTw from '../static/lang/zh-tw.js' //  繁体中文
import langEn from '../static/lang/en.js' //  英文
import langHe from '../static/lang/he.js' //  希伯来语
import langEs from '../static/lang/es.js' //  西班牙语
import langIt from '../static/lang/it.js' //  意大利语
import langUk from '../static/lang/uk.js' //  乌克兰语
import langFr from '../static/lang/fr.js' //  法语
import langAr from '../static/lang/ar.js' //  阿拉伯语
import langJap from '../static/lang/jap.js' //  日语


import api from './api/api'

import 'common/styles/index.styl'
import 'common/fonts/iconfont.css'

/* vConsole */
// import vConsole from 'vconsole';
// if (process.env.NODE_ENV !== 'production') {
//   new vConsole();
// }

fastclick.attach(document.body)
Vue.prototype.$api = api
Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(Lazyload)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Toast)
Vue.use(Checkbox)
Vue.use(ElementUI)
Vue.use(Uploader)
Vue.use(VueClipboard)

/* 判断过去对语言的选择 */
var jsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
let bLang = window.localStorage.getItem("bLang");
if (bLang == null) {
	window.localStorage.setItem("bLang", jsSrc);
	if (jsSrc === 'zh-cn') {
		document.title = "手机美妆定制";
	} else if (jsSrc === 'zh-tw') {
		document.title = "手機美妝定制";
	} else if (jsSrc === 'he') {
		document.title = "יפה בטלפון.";
	} else if (jsSrc === 'es') {
		document.title = "Personalización de belleza móvil";
	} else if (jsSrc === 'it') {
		document.title = "Personalizzazione della bellezza mobile";
	} else if (jsSrc === 'uk') {
		document.title = "Mobile Beauty Customization";
	} else if (jsSrc === 'fr') {
		document.title = "Personnalisation beauté mobile";
	} else if (jsSrc === 'ar') {
		document.title = "الهاتف المحمول جمال المكياج حسب الطلب";
	} else if (jsSrc === 'jap') {
		document.title = "スキンシールカスタマイズアプリ";
	} else {
		document.title = "CELLPHONE BEAUTIFY";
	}
} else {
	if (bLang === 'zh-cn') {
		document.title = "手机美妆定制";
	} else if (bLang === 'zh-tw') {
		document.title = "手機美妝定制";
	} else if (bLang === 'he') {
		document.title = "יפה בטלפון.";
	} else if (bLang === 'es') {
		document.title = "Personalización de belleza móvil";
	} else if (bLang === 'it') {
		document.title = "Personalizzazione della bellezza mobile";
	} else if (bLang === 'uk') {
		document.title = "Mobile Beauty Customization";
	} else if (bLang === 'fr') {
		document.title = "Personnalisation beauté mobile";
	} else if (bLang === 'ar') {
		document.title = "الهاتف المحمول جمال المكياج حسب الطلب";
	} else if (bLang === 'jap') {
		document.title = "スキンシールカスタマイズアプリ";
	} else {
		document.title = "CELLPHONE BEAUTIFY";
	}
}

// 简体中文 zh-cn
// 繁体中文 zh-tw
// 英文 en
// 希伯来语 he
// 西班牙语 es
// 意大利语 it
// 乌克兰语 uk
// 法语 fr
// 阿拉伯语 ar
// 日语 jap
const i18n = new VueI18n({
	locale: window.localStorage.getItem('bLang'),
	messages: {
		'zh-cn': {
			...langZhCn
		},
		'zh-tw': {
			...langZhTw
		},
		'en': {
			...langEn
		},
		'he': {
			...langHe
		},
		'es': {
			...langEs
		},
		'it': {
			...langIt
		},
		'uk': {
			...langUk
		},
		'fr': {
			...langFr
		},
		'ar': {
			...langAr
		},
		'jap': {
			...langJap
		}
	}
})

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
