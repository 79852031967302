import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	routes: [{
		path: '/',
		name: '/home',
		component: () => import('views/home/index')
	}, {
		path: '/home',
		name: 'index',
		component: () => import('views/home/index'),
		meta: {
			title: '首页',
			keepAlive: false
		}
	}, {
		path: '/custom',
		name: 'index',
		component: () => import('views/custom/index'),
		meta: {
			title: '选择图片（汉图）'
		}
	}, {
		path: '/hotCustom',
		name: 'index',
		component: () => import('views/hotCustom/index'),
		meta: {
			title: '选择图片（热升华）',
			keepAlive: false
		}
	}, {
		path: '/preview',
		name: 'index',
		component: () => import('views/preview/index'),
		meta: {
			title: '确定打印',
			keepAlive: false
		}
	}, {
		path: '/success',
		name: 'index',
		component: () => import('views/success/index'),
		meta: {
			title: '提交成功',
			keepAlive: false
		}
	}, {
		path: '/customerProtocol',
		name: 'customerProtocol',
		component: () => import('views/protocol/customerProtocol'),
		meta: {
			title: '图案定制协议',
			keepAlive: false
		}
	}]
})
