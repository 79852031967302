import * as types from './mutation-types'

const mutations = {
	[types.SET_DEVSN](state, devSn) {
		state.devSn = devSn
	},
	[types.SET_MID](state, mid) {
		state.mid = mid
	},
	[types.SET_CUSTID](state, custId) {
		state.custId = custId
	},
	[types.SET_TYPE](state, type) {
		state.type = type
	},
	[types.SET_FIRSTLOADPDF](state, firstLoadPdf) {
		state.firstLoadPdf = firstLoadPdf
	},
	[types.SET_PICID](state, picId) {
		state.picId = picId
	},
	[types.SET_PDFIMG](state, pdfImg) {
		state.pdfImg = pdfImg
	},
	[types.SET_SPRITEARR](state, spriteArr) {
		state.spriteArr = spriteArr
	},
	[types.SET_MODELID](state, modelId) {
		state.modelId = modelId
	},
	[types.SET_MODELNAME](state, modelName) {
		state.modelName = modelName
	},
}

export default mutations
