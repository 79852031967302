import axios from 'axios'
import {
	Toast
} from 'vant'

let lang = window.localStorage.getItem('bLang');
let language = 'zh-cn';
if (lang === 'en') {
	language = 'en-us';
}
const service = axios.create({
	timeout: 60000, // 请求超时时间
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'Version': '1.0.0',
		'Authorization': '0',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'lang': language
	},
	withCredentials: false
})

// request拦截器，两个参数function,第一个在请求发送之前做一些事,第二个当出现请求错误时做一些事
service.interceptors.request.use(config => {
	return config
}, error => {
	// Do something with request error
	Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
	response => {
		return response.data
	},
	error => {
		Toast({
			message: "Network abnormality, please check the network", //  error.msg'网络异常，请检查网络'
			type: 'error',
			duration: 3 * 1000
		})
		return Promise.reject(error)
	}
)

export default service
