module.exports = {
	C: {
		Next: 'הבא',
		Copyright: 'רשמי',
		Tips: 'האזור שבתוך הקו האדום מייצג את מכשירך ומהווה את אזור ההדפסה וחיתוך ע"פ הדגם הנבחר .',
		Tips2: 'יש לשים לב להתאמת התמונה ביחס לגודל המכשיר ועדשות המצלמה .',
		Print: 'תוצאת הדפסה',
		PResult1: 'תקין',
		PResult2: 'מטושטש',
		PResult3: 'ברור',
		NoData: 'אין נתונים',
		ChooseModel: 'יש לבחור דגם',
		HadChooseModel: 'שינוי דגם',
		Cancel: 'ביטול',
		Confirm: 'אישור',
		Restore: 'איחזור',
		Return: 'חזרה',
		Warning: 'הזהרה',
		GongNeng: 'התאמה וסיבוב',
		TuKu: 'גלריית תמונות',
		Photo: 'טען תמונה',
		Sticker: 'מדבקה',
		Text: 'טקסט',
		AddText: 'הוספת טקסט',
		PleaseChoose: 'ייש לבחור גופן בבקשה',
		PleaseChooseText: 'יש להכניס מלל',
		PictureEdit: 'התאמת תמונה',
		WarningTips: 'יש לטעון תמונה בפורמט PNG , JPG , JPEG בבקשה',
		UploadLimit: 'גודל התמונה הנטנעת אינו יכול לעלות על 30MB !',
		ModelTips: 'אנא ביחרו את הדגם תחילה',
		PicTips: 'אנא טענו את התמונה שברצונכם להתאים תחילה',
		PicChooseTips: 'אנא בחירו את התמונה שברצונכם להתאים תחילה',
		PicMake: 'מפיק תמונה',
		PicLoad: 'מעלה לשרת',
		Loading: 'טוען',
		Reminder: 'הסכם משתמש וזכויות יוצרים',
		UploadTips0: 'בבקשה קרא ותבדוק',
		UploadTips1: 'יש לוודא שהתמונות המועלות הן ברורות ואיכותיות . חל איסור להעלות תמונות פוגעניות ואו תמונות המפרות זכויות יוצרים . אישור זה מהווה הסכמה כי הינך בעל הזכויות בחומרים המועלים בהתאם',
		UploadTips2_1: 'להסכם השימוש .',
		UploadTips2_2: 'פרוטוקול ההתאמה של דפוס תיקי טלפון',
		UploadTips3: '',
		Back: 'לא מאושר',
		Know: 'ההסכם מאושר',
		PhoneProtocol: 'פרוטוקול ההתאמה של דפוס',
		NoPic: 'אין יותר תמונות להצגה !',
		NoSn: 'המידע אודות המדפסת אינו קיים',
		NoMid: 'המידע אודות חומר ההדפסה אינו קיים',
		CusDisabled: 'הלקוח נחסם לשימוש',
		NoCustomerInfo: 'המידע אודות הלקוח אינו קיים',
		SnDisabled: 'המדפסת אינה פועלת קשורה יש לפנות לתמיכה טכנית',
		NoSystem: 'עדיין אין הגדרות מערכת',
		Loadingg: 'טוען',
		GeneratingOrders: 'יצירת הזמנות',
		DownloadInfo: 'יש לעשות צילום מסך על מנת לשמור את התמונה . או יש להשתמש בדפדפן בכדי לפתוח ולשמור את התמונה',
		DownloadLoading: 'שומר את התמונות',
		DownloadSuccess: 'התמונה נשמרה בהצלחה בגלריית התמונות',
		PhotoChange: 'יש לבחור מחדש את התמונה בעקבות שינוי הדגם המבוקש',
		SelectPicture: 'בחירת תמונה',
		MapTransfer: 'לגלריית מכשיר',
		ClosePicture: 'סגור',
		LoadingResource: 'טוען נתונים',
		NoModelList: "אין מידע אודות הדגם . יש להכניס דגם מחדש",
		NoModelLists: "אין מידע אודות הדגם",
		BrandName: "מותג",
		JixinName: "דגם",
		SearchContent: 'חיפוש דגם',
		PopularModels: 'חיפוש מותג',
		SuccessRestore: 'האחזור הצליח',
		SuccessRestoreRotate: 'אחזור זווית הסיבוב הצליח',
		FunctionNoOpen: 'הפונקציה תחת פיתוח',
		Rotate: 'סיבוב מותאם',
		Scale: 'קנה מידה',
		Acoustic: 'אקוסטי',
		RotateLeft15: '-15',
		RotateRight15: '+15',
		ReadAndCheckTheAgreement: 'בבקשה קרא ולבדוק קודם את ההסכם',
		FlipHorizontal: 'הפוך אופקי',
		FlipVertical: 'הפך אנטי',
		FlipHorizontalYes: 'הפוך אופקי',
		FlipHorizontalNo: 'הפיכה אופקית כבויה',
		FlipVerticalYes: 'הסיבוב אנטי מופעל',
		FlipVerticalNo: 'הפיכה אנטית כבויה'
	},
	P: {
		ConfirmPrint: 'אישור את הדפסה',
		PrintPreview: 'תצוגה מקדימה',
		Model: 'מודל טלפון',
		Remark: 'מידע הבחנה, כמו: מספר טלפון, כתובת דואר אלקטרוני, לחקירה פקודה',
		SubmitPrint: 'שליחה להדפסה'
	},
	S: {
		SubmitSuccess: 'לשלוח בהצלחה ~',
		PrintInfo: 'ההזמנה שלי',
		PrintSceen: 'השתמש בקוד זה לבצוע ההדפסה',
		PrintNo: 'קוד מספר',
		Save: 'שמור בגלריה',
		CancelOrder: 'ביטול ומחיקת הזמנה',
		CancelOrderSuccess: 'הביטול בוצע בהצלחה',
		CancelOrderFail: 'ביטול ההזמנה נכשל. אנא יצור קשר עם שירות לקוחות',
		ScreenshotSave: 'בבקשה תעשה צילום מסך ידני כדי לשמור את מידע ההזמנה',
		copySuccess: 'העתק מוצלח',
		copyError: 'העתק נכשל'
	}
}
