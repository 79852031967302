module.exports = {
	C: {
		Next: 'Suivant',
		Copyright: 'Officiel',
		Tips: "info : la partie en pointillé rouge sera la partie ou l'image sera imprimée",
		Tips2: 'Le cadre représente une prévisualisation du produit fini',
		Print: "Résultat d'impression",
		PResult1: 'Normal',
		PResult2: 'Flou',
		PResult3: 'Clair',
		NoData: 'Aucune donnée',
		ChooseModel: 'Choisir un modèle de téléphone',
		HadChooseModel: 'Changer le modèle',
		Cancel: 'Annuler',
		Confirm: 'Confirmer',
		Restore: 'Restaurer',
		Return: 'Retour',
		Warning: 'Avertissement',
		GongNeng: 'Personnalisé',
		TuKu: 'Gallerie',
		Photo: 'Photo',
		Sticker: 'Autocollant',
		Text: 'Texte',
		AddText: 'Ajouter du texte',
		PleaseChoose: 'Sélectionner une police',
		PleaseChooseText: 'Entrer du texte',
		PictureEdit: "Ajuster l'image",
		WarningTips: 'Importer une image au format PNG, JPG ou JPEG',
		UploadLimit: "L'image ne peut pas dépasser 30MB !",
		ModelTips: "Selectionner d'abord le modèle svp !",
		PicTips: "Importer d'abord l'image svp !",
		PicChooseTips: "Selectionner d'abord l'image à ajuster svp !",
		PicMake: 'Réalisation',
		PicLoad: 'Importation',
		Loading: 'Chargement',
		Reminder: 'Rappel',
		UploadTips0: 'Veuillez lire et cocher',
		UploadTips1: "S'il vous plaît assurez - vous que les images téléchargées sont claires et disponibles et ne peuvent pas télécharger des images illégales, offensantes. Veuillez lire attentivement",
		UploadTips2_1: "Accord de personnalisation de motif d'autocollant de téléphone portable",
		UploadTips2_2: 'Accord de personnalisation de motif de cas de téléphone portable',
		UploadTips3: ", en cliquant sur 'accepter', vous indiquez que vous avez lu et accepté l'accord.",
		Back: "Pas d'accord",
		Know: "J'accepte et je continue",
		PhoneProtocol: 'Protocole de personnalisation de modèle',
		NoPic: "Plus d'images !",
		NoSn: "Aucune information sur l'appareil",
		NoMid: 'Aucune information sur le matériel',
		CusDisabled: 'Le client a été désactivé',
		NoCustomerInfo: 'Aucune information sur le client',
		SnDisabled: "L'appareil à un problème, veuillez contacter le service client !",
		NoSystem: 'Aucune configuration',
		Loadingg: 'Chargement',
		GeneratingOrders: 'Commande en cours',
		DownloadInfo: "Veuillez prendre une capture d'écran pour enregistrer l'image ou utiliser un navigateur pour ouvrir et enregistrer l'image",
		DownloadLoading: "Enregistrer l'image",
		DownloadSuccess: "La photo a été enregistrée avec succès dans l'album",
		PhotoChange: "En raison du changement de modèle, veuillez sélectionner à nouveau l'image",
		SelectPicture: "Sélectionnez l'image",
		MapTransfer: "Importez l'image",
		ClosePicture: 'Fermer',
		LoadingResource: 'Les ressources sont en cours de chargement',
		NoModelList: "Aucune donnée de modèle, veuillez saisir à nouveau",
		NoModelLists: "Pas encore de données de modèle",
		BrandName: "Marque",
		JixinName: "Nom du modèle",
		SearchContent: 'Nom du modèle',
		PopularModels: 'Marque du modèle',
		SuccessRestore: 'Récupération réussie',
		SuccessRestoreRotate: 'Angle de rotation initialisé avec succès',
		FunctionNoOpen: 'Cette fonction est encore en développement',
		Rotate: 'Faire tourner',
		Scale: 'Zoom',
		Acoustic: 'Image miroir',
		RotateLeft15: 'Inverser de 15 degrés',
		RotateRight15: 'Tourner de 15 degrés',
		ReadAndCheckTheAgreement: "Veuillez d'abord lire et cocher l'accord",
		FlipHorizontal: 'Retournement horizontal',
		FlipVertical: 'Retournement vertical',
		FlipHorizontalYes: 'Basculement horizontal activé',
		FlipHorizontalNo: 'Fermé Horizontal Flip',
		FlipVerticalYes: 'Le retournement vertical est activé',
		FlipVerticalNo: 'Fermé Vertical Flip'
	},
	P: {
		ConfirmPrint: "Confirmez l'impression",
		PrintPreview: 'Prévisualisation',
		Model: 'Modèle du téléphone',
		Remark: 'Information tel que : numéro de téléphone portable, adresse e-mail (pour une demande de commande)',
		SubmitPrint: "Valider l'impression"
	},
	S: {
		SubmitSuccess: 'Impression envoyé',
		PrintInfo: 'Information de la commande',
		PrintSceen: "Veuillez enregistrer la capture d'écran et la montrer au personnel pour l'imprimer",
		PrintNo: "Numéro d'impression",
		Save: "Enregistrer dans l'album photo",
		CancelOrder: 'Annuler la commande',
		CancelOrderSuccess: 'La commande a été annulée',
		CancelOrderFail: "L'annulation de la commande a échouée. Contacter le service client.",
		ScreenshotSave: "Veuillez utiliser la capture d'écran de votre téléphone pour sauvegarder les informations de commande",
		copySuccess: 'Copie réussie',
		copyError: 'La copie a échoué'
	}
}
